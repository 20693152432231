import { defineStore } from 'pinia';
import {
  apiCarrierTruckAuth,
  apiCheckCarrierCarNumber,
  // apiUpdateCarrierTruckInfo,
  updateTruckInfoCaptainApi,
  afreshTruckInfoCaptainApi,
  apiAddCarrierTruckByCarNumber
} from '@api/carrier';
import {
  apiGetTruckDetail,
  apiTruckAuth,
  apiCheckCarNumber,
  apiWithdrawTruck,
  apiUpdateCarDetail,
  updateTruckInfoApi,
  apiAfreshCarDetail,
  apiAddTruckByCarNumber,
  getTrailerTypesApi,
  getLengthAxlesMapApi,
  getLengthRecommendApi
} from '@api/mine';
import { useUserStore } from '@/store/user';
import { Toast, Dialog } from 'vant';
import { useCarrierStore } from '@/store/carrier';
import { formatToDateTime } from '@wlhy-web-lib/shared';
import { plateEnumMap, carsTypeEnumMap } from '@/views/mine/constants';
import { cloneDeep } from 'lodash-es';
import { yuan2cent, weight2gram, gram2weight, cent2yuan } from '@/utils/unit';
import { objectCompare } from '@/utils/authMode';
import dayjs from 'dayjs';
import { PageEnum } from '@/enums/pageEnum';
import router from '@/router';

const parseTimeStr = (time, isEndofMonth = false) => {
  if (!time || !dayjs(time).isValid()) return null;
  return isEndofMonth
    ? dayjs(time)
        .endOf('month')
        .valueOf() - 1000
    : dayjs(time).valueOf();
};
const parseTime = (time, format = 'yyyy-MM-dd') => {
  if (!time) return null;
  return formatToDateTime(time, format);
};
export const emptyForm = () => ({
  businessName: '', // 业户名称
  carLicensePlate: '', // 车牌号
  driverId: '', // 司机id
  drivingLicenseMainUrl: '', // 行驶证主页url
  drivingLicenseSideUrl: '', // 行驶证其他页url
  energyType: '', // 能源类型
  fileNumber: '', // 档案编号
  identifyCode: '', // 车辆识别代号
  issueDate: '', // 行驶证发证日期
  issueOrganization: '', // 行驶证发证机关
  loadWeight: '', // 核定载质量
  curbWeight: null,
  inspectionEndDate: null,
  validEndDate: null,
  outlineDimensionHeight: '', // 外廓尺寸高
  outlineDimensionLength: '', // 外廓尺寸长
  outlineDimensionWidth: '', // 外廓尺寸宽
  owner: '', // 车辆所有人
  plateColor: '', // 车牌颜色
  registerDate: '', // 行驶证注册日期
  roadTransportNumber: '', // 道路运输证号
  roadTransportPermitNumber: '', // 道路运输许可证号
  totalWeight: '', // 总质量
  trailerPlateNumber: '', // 挂车牌照号
  transportLicenseMainUrl: '', //道路运输证
  transportLicenseSideUrl: '', // 道路运输证其他页
  carWithOwnerUrl: '',
  truckId: null, // 车辆ID
  truckTypeCode: '', // 车辆类型代码
  truckTypeName: '', // 车辆类型名称
  useNature: '', // 使用性质
  reasonKeys: [],
  trailerType: null,
  trailerTypeCode: null,
  trailerLoadWeight: null,
  loadLength: null,
  axles: null,
  trailerDrivingLicenseMainUrl: '', // 行驶证主页url
  trailerDrivingLicenseSideUrl: '' // 行驶证其他页url
});

const refuseReasonKeys = {
  truckTypeCode: 'truckTypeName'
};

export const useTruckVerifiedStore = defineStore({
  id: 'truck-verified',
  state: () => ({
    verifiedInfo: Object.assign({}, emptyForm()),

    isAmendTruckAuthInfo: false, // 表示 认证通过重新认证的状态, 废弃
    // 模式认证配置
    authModeFields: {},
    originVerifiedInfo: {}, // 用来比对数据
    imagePass: false, // 行驶证是否通过
    transportImagePass: false, // 道路运输证是否通过
    auditStatusInfo: 0,
    roadDisabled: false,
    trailerTypeOptions: [],
    axlesOptions: [],
    lengthOptions: [],
    lengthAxlesMap: [],
    lastStep: [],
    step: 0
  }),
  actions: {
    updateInfo(val) {
      this.verifiedInfo = Object.assign(this.verifiedInfo, val);
    },
    resetStep() {
      this.step = 0;
      this.lastStep = [];
    },
    updateImagePass(val) {
      this.imagePass = val;
    },
    updateTransportImagePass(val) {
      this.transportImagePass = val;
    },
    updateAuditStatusInfo(val) {
      this.auditStatusInfo = val;
    },
    updateAuthModeFields(fields) {
      this.authModeFields = fields;
    },
    clearInfo() {
      this.verifiedInfo = { ...emptyForm() };
      this.verifiedInfo.carLicensePlate = '';
      this.verifiedInfo.drivingLicenseMainUrl = '';
      this.verifiedInfo.drivingLicenseSideUrl = '';
      this.step = 0;
      this.lastStep = [];
      this.isAmendTruckAuthInfo = false;
      this.imagePass = false;
      this.transportImagePass = false;
      this.auditStatusInfo = 0;
      this.roadDisabled = false;
    },
    async updateVerifiedInfo() {
      const userStore = useUserStore();
      const carrierStore = useCarrierStore();
      Toast.loading({
        duration: 100, // 持续展示 toast
        message: '车辆数据上传中...'
      });
      try {
        const params = {
          // 进行数据处理
          ...this.verifiedInfo,
          driverId: userStore?.driverAuthVO?.id,
          issueDate: parseTimeStr(this.verifiedInfo.issueDate),
          registerDate: parseTimeStr(this.verifiedInfo.registerDate),
          inspectionEndDate: parseTimeStr(this.verifiedInfo.inspectionEndDate, true),
          validEndDate: parseTimeStr(this.verifiedInfo.validEndDate),
          outlineDimensionLength: +this.verifiedInfo.outlineDimensionLength,
          outlineDimensionWidth: +this.verifiedInfo.outlineDimensionWidth,
          outlineDimensionHeight: +this.verifiedInfo.outlineDimensionHeight,
          loadWeight: +this.verifiedInfo.loadWeight * 1000,
          totalWeight: +this.verifiedInfo.totalWeight * 1000,
          curbWeight: this.verifiedInfo.curbWeight ? +this.verifiedInfo.curbWeight * 1000 : null,
          energyType: this.verifiedInfo.energyTypeCode,
          plateColor: +this.verifiedInfo.plateColorCode,
          trailerLoadWeight: this.verifiedInfo.trailerLoadWeight
            ? weight2gram(this.verifiedInfo.trailerLoadWeight, 2)
            : null,
          loadLength: this.verifiedInfo.loadLength ? yuan2cent(this.verifiedInfo.loadLength) : null,
          axles: this.verifiedInfo.axles ? parseInt(this.verifiedInfo.axles) : null
        };

        let res;
        // 1.3.4 认证通过，重新编辑
        // if (this.isAmendTruckAuthInfo) {
        if (objectCompare(this.originVerifiedInfo, params)) {
          router.push(userStore.isDriver ? PageEnum.BASE_CAR_LIST : PageEnum.CARRIER_FLEET);
          return;
        }

        res = userStore.isDriver
          ? await updateTruckInfoApi(params)
          : await updateTruckInfoCaptainApi({ ...params, carrierId: userStore.carrierInfo.carrierId });
        const { data } = res;
        if (!userStore.isDriver) {
          await carrierStore.getFleetTrucksAction(); // 如果是车队长需要提前更新车队长车辆列表
        }
        if (data) {
          this.setCurrentStep(4);
        }
      } catch (err) {
        console.log(err);
      } finally {
        setTimeout(() => {
          Toast.clear();
        }, 1000);
      }
    },
    setRoadDisabled(val) {
      this.roadDisabled = val;
    },
    setCurrentStep(step) {
      const curStep = this.step;
      this.step = step;
      const lastStep = this.lastStep || [];
      const index = lastStep.indexOf(curStep);
      if (index > -1) {
        lastStep.splice(index, 1);
      }
      this.lastStep = [...lastStep, curStep];
    },
    backStep() {
      const lastStep = this.lastStep || [];
      if (!lastStep.length) return;
      const step = lastStep.pop();
      this.lastStep = lastStep;
      this.step = step;
    },
    //新增接口
    async submitVerifiedInfo() {
      // 添加车辆
      const userStore = useUserStore();
      const carrierStore = useCarrierStore();
      Toast.loading({
        duration: 100, // 持续展示 toast
        message: '车辆数据上传中...'
      });
      try {
        const params = {
          // 进行数据处理
          ...this.verifiedInfo,
          driverId: userStore?.driverAuthVO?.id,
          issueDate: parseTimeStr(this.verifiedInfo.issueDate),
          registerDate: parseTimeStr(this.verifiedInfo.registerDate),
          inspectionEndDate: parseTimeStr(this.verifiedInfo.inspectionEndDate, true),
          validEndDate: parseTimeStr(this.verifiedInfo.validEndDate),
          outlineDimensionLength: +this.verifiedInfo.outlineDimensionLength,
          outlineDimensionWidth: +this.verifiedInfo.outlineDimensionWidth,
          outlineDimensionHeight: +this.verifiedInfo.outlineDimensionHeight,
          loadWeight: +this.verifiedInfo.loadWeight * 1000,
          totalWeight: +this.verifiedInfo.totalWeight * 1000,
          curbWeight: this.verifiedInfo.curbWeight ? +this.verifiedInfo.curbWeight * 1000 : null,
          energyType: this.verifiedInfo.energyTypeCode,
          plateColor: +this.verifiedInfo.plateColorCode,
          trailerLoadWeight: this.verifiedInfo.trailerLoadWeight
            ? weight2gram(this.verifiedInfo.trailerLoadWeight, 2)
            : null,
          loadLength: this.verifiedInfo.loadLength ? yuan2cent(this.verifiedInfo.loadLength) : null,
          axles: this.verifiedInfo.axles ? parseInt(this.verifiedInfo.axles) : null
        };
        let res;
        // if (this.auditStatusInfo == 1) {
        //   res = userStore.isDriver
        //     ? await apiAfreshCarDetail(params)
        //     : await afreshTruckInfoCaptainApi({
        //         ...params,
        //         carrierId: userStore.carrierInfo.carrierId
        //       });
        // } else {
        res = userStore.isDriver
          ? await apiTruckAuth(params)
          : await apiCarrierTruckAuth({
              ...params,
              carrierId: userStore.carrierInfo.carrierId
            });

        const { data } = res;
        if (!userStore.isDriver) {
          await carrierStore.getFleetTrucksAction(); // 如果是车队长需要提前更新车队长车辆列表
        }
        if (data) {
          this.setCurrentStep(4);
        }
      } catch (err) {
        console.log(err);
      } finally {
        setTimeout(() => {
          Toast.clear();
        }, 1000);
      }
    },
    async resetAuditTruck() {
      // 撤回操作
      await apiWithdrawTruck({ truckId: this.verifiedInfo.truckId });
    },
    async queryVerifiedInfo(truckId, resolve) {
      // 获取车辆详情
      Toast.loading({
        duration: 0, // 持续展示 toast
        message: '车辆数据加载中...'
      });
      try {
        const res = await apiGetTruckDetail({
          truckId: truckId
        });
        const { data } = res;
        if (data) {
          // 存贮数据做比对
          // 只有认证通过修改才需要比较
          // if (this.isAmendTruckAuthInfo) {
          const { step, driverId, auditStatus, energyType, truckId, registerDate, issueDate, ...fieldsMap } = data;
          this.originVerifiedInfo = cloneDeep(fieldsMap);
          this.originVerifiedInfo.trailerPlateNumber = fieldsMap.trailerPlateNumber || '';
          // }

          this.verifiedInfo = {
            ...data,
            truckId: truckId,
            issueDate: parseTime(data.issueDate, 'yyyy-MM-dd'),
            registerDate: parseTime(data.registerDate, 'yyyy-MM-dd'),
            inspectionEndDate: parseTime(data.inspectionEndDate, 'yyyy-MM'),
            validEndDate: parseTime(data.validEndDate, 'yyyy-MM-dd'),
            loadWeight: data.loadWeight / 1000,
            totalWeight: data.totalWeight / 1000,
            curbWeight: data.curbWeight ? data.curbWeight / 1000 : null,
            energyTypeCode: data.energyType,
            energyType: carsTypeEnumMap[data.energyType], // 枚举
            plateColorCode: data.plateColor,
            plateColor: plateEnumMap[data.plateColor], // 枚举
            reasonKeys: data?.refuseReason?.map(item => refuseReasonKeys[item.item] || item.item) || [],
            trailerLoadWeight: data.trailerLoadWeight ? gram2weight(data.trailerLoadWeight, 2) : null,
            loadLength: data.loadLength ? cent2yuan(data.loadLength) : null,
            axles: data.axles ? data.axles + '轴' : null
          };
          this.resetStep();
        }
      } catch (err) {
        console.log(err);
      } finally {
        typeof resolve === 'function' && resolve();
        setTimeout(() => {
          Toast.clear();
        }, 500);
      }
    },

    async checkTruckExist(carLicensePlate) {
      const userStore = useUserStore();
      const carrierStore = useCarrierStore();
      Toast.loading({
        duration: 0, // 持续展示 toast
        message: '车牌号校验中...'
      });
      try {
        const res = userStore.isDriver
          ? await apiCheckCarNumber({
              carLicensePlate,
              driverId: userStore?.driverAuthVO?.id
            })
          : await apiCheckCarrierCarNumber({
              carLicensePlate,
              carrierId: userStore.carrierInfo.carrierId
            });
        const { data } = res;
        return data ? 1 : 0;
      } catch (e) {
        return -1;
      } finally {
        setTimeout(() => {
          Toast.clear();
        }, 500);
      }
    },
    async addTruckByExist(carLicensePlate) {
      const userStore = useUserStore();
      const carrierStore = useCarrierStore();
      Toast.loading({
        duration: 0, // 持续展示 toast
        message: '添加中...'
      });
      try {
        userStore.isDriver
          ? await apiAddTruckByCarNumber({
              carLicensePlate,
              driverId: userStore?.driverAuthVO?.id
            })
          : await apiAddCarrierTruckByCarNumber({
              carLicensePlate,
              carrierId: userStore.carrierInfo.carrierId
            });
        this.clearInfo();
        // 一旦车辆列表变动就需要重新更新车队长的车辆列表
        !userStore.isDriver && carrierStore.getFleetTrucksAction();
        return true;
      } catch (e) {
        return false;
      } finally {
        Toast.clear();
      }
    },
    async queryCheckTruck(resolve, reject) {
      const userStore = useUserStore();
      const carrierStore = useCarrierStore();
      Toast.loading({
        duration: 0, // 持续展示 toast
        message: '车牌号校验中...'
      });
      try {
        const res = userStore.isDriver
          ? await apiCheckCarNumber({
              carLicensePlate: this.verifiedInfo.carLicensePlate,
              driverId: userStore?.driverAuthVO?.id
            })
          : await apiCheckCarrierCarNumber({
              carLicensePlate: this.verifiedInfo.carLicensePlate,
              carrierId: userStore.carrierInfo.carrierId
            });
        const { data } = res;
        if (!data) {
          typeof resolve === 'function' && resolve();
        } else {
          Dialog.confirm({
            message: '车辆添加成功!',
            confirmButtonText: '点击查看',
            closeOnClickOverlay: true
          })
            .then(async () => {
              await this.clearInfo();
              // 一旦车辆列表变动就需要重新更新车队长的车辆列表
              !userStore.isDriver && (await carrierStore.getFleetTrucksAction());
              typeof reject === 'function' && reject();
            })
            .catch(() => {});
        }
      } catch (err) {
        await this.clearInfo();
        setTimeout(() => {
          typeof reject === 'function' && reject();
        }, 500);
      } finally {
        setTimeout(() => {
          Toast.clear();
        }, 500);
      }
    },
    async getTrailerTypesAction() {
      try {
        const { data } = await getTrailerTypesApi();
        this.trailerTypeOptions = data || [];
      } catch (e) {
        //
      }
    },
    async getRecommendLengthAction() {
      try {
        const { data } = await getLengthRecommendApi();
        this.lengthOptions = data ? data.map(it => ({ value: it, text: it })) : [];
      } catch (e) {
        //
      }
    },
    async getLengthMapperAction() {
      try {
        const { data } = await getLengthAxlesMapApi();
        if (data) {
          const parsed = JSON.parse(data);
          const lens = Object.keys(parsed)
            .map(it => +it)
            .sort((a, b) => a - b);
          const result = [];
          for (let i = 0; i < lens.length; i++) {
            const max = lens[i];
            const options = (parsed[max] || []).map(it => {
              const val = it + '轴';
              return { value: val, text: val };
            });
            if (i === 0) {
              result.push({ min: 0, max, options });
            } else {
              result.push({ min: lens[i - 1], max, options });
            }
          }
          this.lengthAxlesMap = result;
        }
      } catch (e) {
        //
      }
    },
    async getTuckInitConfig() {
      return Promise.all([this.getRecommendLengthAction(), this.getTrailerTypesAction(), this.getLengthMapperAction()]);
    },
    getAxlesByLength(len) {
      if (!len || isNaN(len)) {
        this.axlesOptions = [];
      } else {
        const num = +len;
        const target = this.lengthAxlesMap.find(it => {
          return num > it.min && num <= it.max;
        });
        if (target) {
          this.axlesOptions = target.options || [];
        } else {
          this.axlesOptions = [];
        }
      }
    }
  }
});
